import { useFetch } from '@/api';
import { downloadAsFile } from '@/utils/functions';

export const getKeyLogs = async (id) => {
  const { axiosWrapper } = useFetch();

  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: `/merchants/api/keys/logs/${id}`,
    params: { isFreshResponse: true },
  });

  return { data, isSuccess };
};

export const downloadKeyLogs = async (id, name) => {
  const { axiosWrapper } = useFetch();

  const response = await axiosWrapper({
    type: 'get',
    url: `/merchants/api/keys/logs/download/${id}`,
    responseType: 'blob',
    params: { isFreshResponse: true },
  });

  if (response.result?.data) {
    downloadAsFile({
      data: response.result.data,
      filename: name,
      type: 'log',
    });
  }
};
