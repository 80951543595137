<template>
  <div class="wrapper">
    <AppIcon
      :name="name"
      :width="size"
      is-img-tag
      class="flag"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: '32px',
  },
});

const shadowSize = computed(() => `${props.size.replace('px', '') * 0.07}px`);
</script>

<style lang="scss" scoped>
.wrapper {
  flex-shrink: 1;
  width: v-bind('size');
  aspect-ratio: 16 / 12;
  overflow: hidden;
  border-radius: 10%;
  position: relative;

  &:after {
    content: '';
    width: v-bind('size');
    height: 100%;
    aspect-ratio: 16 / 12;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10%;
    box-shadow: inset 0 0 0 v-bind('shadowSize') var(--color-black-01);
  }

  .flag {
    width: v-bind('size');
    aspect-ratio: 16 / 12;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include transition-base('all');
  }
}
</style>
